export const HERO_TITLE = ["HARVESTING FRESHNESS CULTIVATING QUALITY"];
export const HERO_DESCRIPTION = [
  "Discover the exceptional taste of our premium mushrooms at KP KAALAN. Elevate your dishes with the finest Oyster and Button mushrooms, straight from our farm to your table.",
];

export const HERO_IMAGES = [
  {
    lg: "https://media.graphassets.com/3ro8LoSRxQcb7iva0S2w",
    sm: "https://media.graphassets.com/P8Nwn1Rh6H2qUHE6dJSg",
    link:"/products/oyster-mushroom"
  },
  {
    lg: "https://media.graphassets.com/voAcfa1aT2yT3a7oFFAq",
    sm: "https://media.graphassets.com/vLNqKdSERP6dwkwx55DY",
    link:"/products/button-mushroom"
  
  },
];

export const PRODUCT_THUMBNAIL = [
  "https://media.graphassets.com/aZ1UzBH4SBOJUjuZVkzA",
  "https://media.graphassets.com/txUpNqlIR4SxWHh4AC0c",
];

export const ABOUT_US_IMAGES = [
  "https://media.graphassets.com/bStVHDaCTKqbSSHphram",
  "https://media.graphassets.com/r2fVxK3mRgiEDu9HVqgu",
  "https://media.graphassets.com/v0yCVaVPSte7bWezJRMj"
];

export const FOUNDER_DATA = [
  {
    name: "BalaGanesh",
    image: "https://media.graphassets.com/fDZ5GApzSrOflmmo65Sb",
    imageFocus: "0% 25%"
  },
  {
    name: "Kalaiselvan",
    image:"https://media.graphassets.com/Sc9dH4nzR5KaXGwmkcFM",
    imageFocus: "0% 20%"
  },
];



export const BASE_URL = "https://kpkaalan.com"